import React, { useState } from 'react';
import axios from 'axios';

const CreateFolder = () => {
  const [folderName, setFolderName] = useState('');
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/drive_folder.php`;


  const handleCreate = async () => {
    setLoading(true);
    const bodyData = {
      status: 'createFolder',
      data: { folderName: folderName },
    };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      console.log(response.data);
      
      if (response.data.success) {
        alert('Folder created. ID: ' + response.data.folderId);
      } else {
        alert('Error: ' + (response.data.error || 'Unknown error'));
      }
    } catch (error) {
      console.error('Error calling createFolder API:', error);
    }
  };

  return (
    <div className='p-10'>
      <h3>Create Folder</h3>
      <input
        type="text"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
        placeholder="Folder Name"
      />
      <button onClick={handleCreate}>Create</button>
    </div>
  );
}

export default CreateFolder;
