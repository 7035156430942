import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';
import Modal from '../components/Modal';
import ShortDateFormat from '../components/ShortDateFormat';
import PhoneNumber from '../components/PhoneNumber';

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TechInspectionList = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [selectDeliveryId, setSelectDeliveryId] = useState(null);
  const [file, setFile] = useState(null);
  const [Inspections, setInspections] = useState([]);
  const [ContactList, setContactList] = useState([]);
  
  useEffect(() => {
    setLoading(true);
    const fetchInspection = async () => {
      try {
        const response = await axios.get(`${apiUrl}/contracts_crud.php?getTechList=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        if(response.data.success) {
          const logs = Array.isArray(response.data.success) ? response.data.success : [];
          setInspections(logs);
          setContactList(logs.contact_list || []);
        }
        else {
          toast.error(response.data.error, { position: "top-center" });
        }
        
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchInspection();
  }, [apiUrl, navigate]);

  const handleActivation = async(id, status) => {
    if(status === 'pending')
    {
      navigate(`/t/tinspdetail/${id}`);
    } 
    else
    {
      toast.success('Service call activated', { position: 'top-center' });
    }
  }

 return (
    <>
    <HeadingCp label={"Maintenance Inspections Queue"}/>
    <ul role="list" className="divide-y divide-gray-100">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {Inspections.map((log) => (
          <div
            key={log.inspection_id}
            onClick={() => handleActivation(log.inspection_id, log.inspection_status)}
            className={classNames(
              "relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm hover:border-gray-400",
              log.inspection_status !== "pending" ? "opacity-60" : ""
            )}
          >
            {/* STATUS & DATE BLOCK */}
            <div className="flex-shrink-0">
              <span
                className={`
                  rounded px-2 py-1 text-xs font-semibold text-white shadow-sm
                  ${log.inspection_status === "pending" ? "bg-blue-600" : "bg-orange-600"}
                `}
              >
                {log.inspection_status === "pending" ? "Pending" : "Scheduled"}
              </span>
              <p>{log.schedule_time_in || ""}</p>
              {/* If scheduled_due_date is a real date, use <ShortDateFormat> */}
              <p>
                {log.scheduled_due_date
                  ? <ShortDateFormat date={log.scheduled_due_date} />
                  : ""
                }
              </p>
            </div>

            {/* MAIN TEXT & CONTACT LIST BLOCK */}
            <div className="min-w-0 flex-1">
              {/* MAIN TEXT */}
              <p className="text-sm font-medium text-green-700">
                {log.job_site_address}, {log.job_site_city} | {log.customer_name}
              </p>
              <p className="truncate text-xs text-gray-500">
                Call: {log.contact_name} Tel:{" "}
                {log.contact_phone ? <PhoneNumber number={log.contact_phone} /> : ""}
              </p>
              <p className="truncate text-xs text-yellow-700">Contract Notes: {log.notes}</p>
              <p className="text-xs text-orange-700 bg-yellow-50 p-1 mt-1">
                Inspection Notes: {log.inspection_notes}
              </p>

              {/* CONTACT LIST GOES HERE, UNDER THE TEXT */}
              {log.contact_list && log.contact_list.length > 0 && (
                <div className="mt-2 space-y-1">
                  {log.contact_list.map((contact) => (
                    <div
                      key={contact.id}
                      className="flex items-center space-x-2 text-xs text-gray-700"
                    >
                      <span>{contact.contact_name}</span>
                      <span>{contact.contact_phone}</span>
                      <span>{contact.contact_email}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </ul>
    </>
  )
}

export default TechInspectionList