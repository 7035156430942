import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import DateFormat from '../components/DateFormat';
import LegendCP from '../components/LegendCP';
import SubMenu from '../components/SubMenu';
import ModalWindowTechTimesheetLogs from '../components/ModalWindowTechTimesheetLogs';
import ModalTechTimesheet from '../components/ModalTechTimesheet';
import ExpenseFileLocation from '../components/ExpenseFileLocation';
import ModalWindow from '../components/ModalWindow';
import ModalTimesheetNotesSection from '../components/ModalTimesheetNotesSection';
import LoadingSpinner from '../components/LoadingSpinner';
import Truncate from '../components/Truncate';
import { useUserData } from '../context/UserData';
import { dateToUnixTimestamp, addDays } from '../utils/dateToUnixTimestamp';
import CurrencyFormat from '../components/CurrencyFormat';
import { getTodaysDateUtil } from '../utils/DateFormatTodayUtil';
import HeadingCp from '../components/HeadingCp';
import { UtilConvertTimeStampToDate } from '../utils/UtilConvertTimeStampToDate';
import MonthSelectorCp from '../components/MonthSelectorCp';


const TechTimesheetPage = () => {
  const [openModalIdNotes, setOpenModalIdNotes] = useState(null);
  const navigate = useNavigate();
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [timesheet, setTimesheet] = useState({
    id: '',
    tech_id: '',
    expense_file: '',
    expense_amount: '',
    expense_comment: '',
    format: '',
    pay_period: '',
    status: '',
    admin_expense_comment: '',
  });
  const [showAppoved, setShowApproved] = useState(true);
  const [bodyDataNotes, setBodyDataNotes] = useState({
    status: 'add_comment',
    timesheet_id: null,
    data: {}
  });
  const [holidays, setHolidays] = useState([]);
  const [isHoliday, setIsholiday] = useState(false);
  const [activeOvertime, setActiveOvertime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModalId, setOpenModalId] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  const [payperiod, setPayPeriod] = useState(null);
  const [payperiodMonth, setPayPeriodMonth] = useState(null);
  // const [week, setWeek] = useState('active_week');
  const [week, setWeek] = useState('active_week');
  const userData = useUserData();
  const [activeButton, setActiveButton] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [bodyData, setBodyData] = useState({
    status: 'submit_days',
    current_date: '',
    data: {}
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };  

  const handleWeek = (e, date, index) => {
    e.preventDefault();
    // const timeStampDate = dateToUnixTimestamp(date);
    setWeek(date);
    setActiveButton(index); // Set the clicked button as active
  };

  useEffect(() => {
    fetchEmployeeList(week, selectedMonth);
  }, [week, selectedMonth]);

  const fetchEmployeeList = async (week, selectedMonth) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/attendance_crud.php?timesheets=${encodeURIComponent(week)}&&selectedMonth=${selectedMonth}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      const data = response.data;
      if (response.data.error === 'Authentication required') {
        navigate('/Login');
      } else {
        const employee_list = Array.isArray(response.data.employees) ? response.data.employees : [];
        if (employee_list.length === 0) {
          toast.error("No days found!", {
            position: "top-center"
          });
        }
        setFilteredEmployees(employee_list);
        setBodyData(prev => ({ ...prev, current_date: week }));
        setEmployees(employee_list);
        setIsholiday(response.data.holiday === week ? true : false);
        setPayPeriod(response.data.pay_period.nextPayPeriod);
        setPayPeriodMonth(response.data.pay_periods_month);
      }
      if (data.error) {
          toast.error(data.error, {
            position: 'top-center'
          });
        }
    } catch (error) {
        toast.error("Oops minor issue with the data.", {
          position: "top-center"
        });
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
          });
        }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
      setEmployees(employees);
    } else {
      const filtered = employees.filter(employee =>
        (employee.title && employee.title.toLowerCase().includes(value.toLowerCase())) ||
        (employee.department && employee.department.toLowerCase().includes(value.toLowerCase())) ||
        (employee.first_name && employee.first_name.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredEmployees(filtered);
    }
  };

  const handleLogs = (var_test) => {
    setOpenModalId(var_test);
  };

  const handleComplete = async (e) => {
    try {
      const response = await axios.post(apiUrl, { status: 'submit_complete', id: e.idItem }, {
        headers: { 'Content-Type': 'application/json' }
      });
      toast.info('Saved!', {
        position: 'top-center'
      });
    } catch (error) {
      // Handle error
    }
  };

  const handleApprovalExpense = async (id, currentExpenseApproval) => {
    setLoading(true);
    const expenseApproval = currentExpenseApproval === 1 ? 0 : 1;
    const bodyData = {
      status: 'approval_expense',
      data: { timesheet_id: id, status: expenseApproval }
    };
    try {
      const response = await axios.post(`${apiUrl}/attendance_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        setFilteredEmployees(prevEmployees =>
          prevEmployees.map(employee => {
            if (employee.timesheet_expense.id === id) {
              return {
                ...employee,
                timesheet_expense: {
                  ...employee.timesheet_expense,
                  status: expenseApproval
                }
              };
            }
            return employee;
          })
        );
        toast.success('Saved!', {
          position: 'top-center'
        });
      } else {
        toast.error('Error updating', {
          position: 'top-center'
        });
      }
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const handleApprovalOvertime = async (fetchData) => {
    setLoading(true);
    const overtime_id = fetchData?.active_overtime?.id ?? '';
    const active_overtime = fetchData?.active_overtime;
    const tech_id = fetchData?.id;
    const tech_name = `${fetchData?.first_name} ${fetchData?.last_name}`;
    const overtime = fetchData?.total_hrs?.overtime_hrs;
    const pay_period = payperiod?.payperiod;
    const id = fetchData?.id;
    const bodyData = {
      status: 'approval_overtime',
      data: {
        overtime_id: overtime_id,
        active_overtime: active_overtime,
        tech_id: tech_id,
        tech_name: tech_name,
        overtime: overtime,
        pay_period: week,
      }
    };
    try {
      const response = await axios.post(`${apiUrl}/attendance_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        setFilteredEmployees(prevEmployees =>
          prevEmployees.map(employee => {
            if (employee.id === id) {
              return {
                ...employee,
                active_overtime: {
                  ...employee.active_overtime,
                  hours: overtime
                }
              };
            }
            return employee;
          })
        );
        toast.success('Saved!', {
          position: 'top-center'
        });
      } else {
        toast.error('Error updating', {
          position: 'top-center'
        });
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
        position: "top-center"
      });
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const AddComment = (id, comment) => {
    bodyDataNotes.timesheet_id = id;
    bodyDataNotes.data = { admin_expense_comment: comment };
    setFilteredEmployees(prevEmployees =>
      prevEmployees.map(employee => {
        if (employee.timesheet_expense.id === id) {
          return {
            ...employee,
            timesheet_expense: {
              ...employee.timesheet_expense,
              admin_expense_comment: comment
            }
          };
        }
        return employee;
      })
    );
  };

  const handleAddComment = (id) => {
    setOpenModalIdNotes(id);
  };

  const getLinksForJob = (fetchData) => {
    let links = [];
    // links.push({ name: 'Add Comment', click: () => handleAddComment(fetchData?.timesheet_expense?.id) });
    if (!fetchData.active_overtime.hours && fetchData.total_hrs.overtime_hrs) {
      links.push({
        name: 'Approve Overtime',
        click: () => handleApprovalOvertime(fetchData)
      });
    }
    if (fetchData.timesheet_expense.expense_amount) {
      links.push({
        name: fetchData.timesheet_expense.status === 1 ? 'Remove approval' : 'Approve expense',
        click: () => handleApprovalExpense(fetchData.timesheet_expense.id, fetchData.timesheet_expense.status)
      });
    }
    return links;
  };

  const handleNotesView = (id) => {
    setNotesIdOpen(id);
  }

  const currentWeek = () => {
    window.location.reload();
  };

  const handleSaveTimesheetLogs = async (updatedLogs) => {
    setLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/attendance_crud.php`, 
        {
          status: 'update_tech_timesheet',
          data: updatedLogs
        }, 
        {
          headers: { 
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.error === 'Authentication required') {
        navigate('/Login');
      } else if (response.data[0] === 'success') {
          setFilteredEmployees((prevEmployees) =>
          prevEmployees.map((employee) => {
            if (employee.id === updatedLogs.tech_id) {
              const updatedLogsArray = employee.timesheet_logs.map((log) => {
                if (log.id === updatedLogs.id) {
                  return { ...log, ...updatedLogs };
                }
                return log;
              });
              return {
                ...employee,
                timesheet_logs: updatedLogsArray,
              };
            }
            return employee;
          })
        );

        toast.success("Timesheet log updated successfully!", {
          position: "top-center"
        });
      } else if (response.data.error) {
        toast.error(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      } else {
        toast.error("Oops, something went wrong. Please try again.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        {/* <h1 className="text-2xl font-semibold text-gray-500 mb-8">Tech Timesheets</h1> */}
        <HeadingCp label={'Tech Timesheets'} />
        <LegendCP color="fill-red-500" label="Under 80hrs" />
        <LegendCP color="fill-yellow-500" label="Above 30hrs OT" />
        <LegendCP color="fill-green-500" label="Approved Expense" />
        <LegendCP color="fill-green-500" label="Approved Overtime" />
        <br />
        <br />
        <div className="mt-2 sm:mt-0 sm:ml-2 w-[15%] mb-4">
          <MonthSelectorCp onMonthChange={handleMonthChange} />
        </div>
        Pay periods:
        {payperiodMonth ? (
          <>
            {payperiodMonth.map((pay_period_week, index) => (
              <span key={pay_period_week} className="px-2 mt-2">
                <button
                  className={`px-4 py-1 text-white rounded-md
                  ${activeButton === index ? 'bg-green-500' : 'bg-blue-500'}
                  hover:bg-green-600`}
                  onClick={(e) => handleWeek(e, pay_period_week, index)}
                >
                  <DateFormat date={pay_period_week} />
                </button>
              </span>
            ))}
          </>
        ) : (
          <LoadingSpinner children={'Loading...'} />
        )}

        <br />
        <br />
        <div className="flex space-x-3 items-center mb-10">
          <div className="relative rounded-md shadow-sm flex-grow">
            <input
              type="text"
              name="search"
              value={searchTerm}
              onChange={handleSearchChange}
              className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="filter by name | department "
            />
          </div>
        </div>
        {loading ? (
          <LoadingSpinner children={'Loading...'} />
        ) : (
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr className="divide-x divide-gray-400">
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                  ID/Menu
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                  Tech Name
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
                  T-Regular hr
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
                  T-Overtime hr
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
                  Exp. File
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Checked By
                </th>
                <th scope="col" className="hidden pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Tech Expenses
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Admin Comment
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredEmployees.filter(fetchData => showAppoved && fetchData.status === 'complete' ?
                fetchData.status === 'status' : fetchData)
                .map((fetchData, index) => (
                  <tr key={fetchData.id} className={`${fetchData.status === 'void' ? 'line-through text-red-600' : ''}
                                                  ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
                                                  ${fetchData.timesheet_expense.status === 1 ? 'bg-green-300' : ''}
                                                  ${fetchData.status === 'complete' ? 'bg-green-300' : ''} divide-x`}>
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                      {fetchData.timesheet_logs.length > 0 ? (
                        <><SubMenu label={fetchData.id} links={getLinksForJob(fetchData)} idItem={fetchData.id} /></>
                      ) : (fetchData.id)}

                      {openModalId === fetchData.id && (
                        <ModalWindowTechTimesheetLogs job={
                          <ModalTechTimesheet
                            timesheet_logs={fetchData.timesheet_logs}
                            hrs={fetchData.total_hrs}
                            payperiod={payperiod}
                            tech_name={`${fetchData.first_name} ${fetchData.last_name}`}
                            onSave={handleSaveTimesheetLogs}
                          />
                        } closeNotes={() => setOpenModalId(null)} />
                      )}

                      <br />
                      <dl className="font-normal lg:hidden">
                        <dd className="mt-1 truncate text-gray-700">
                          {`${fetchData.first_name} ${fetchData.last_name}`}
                        </dd>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {
                            fetchData.total_hrs.regular_hrs < 0 || fetchData.total_hrs.regular_hrs == null ? '0' : fetchData.total_hrs.regular_hrs
                          } hrs
                        </dd>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {
                            fetchData.total_hrs.overtime_hrs < 0 || fetchData.total_hrs.overtime_hrs == null ? '0' : fetchData.total_hrs.overtime_hrs
                          } hrs
                        </dd>
                      </dl>
                    </td>
                    
                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell hover:bg-blue-300">
                      {
                        fetchData.total_hrs.regular_hrs > 0 || fetchData.total_hrs.regular_hrs !== null ? (
                          <>
                            <span className="hover:cursor-pointer" onClick={() => handleLogs(fetchData.id)}>
                              <span className='text-gray-800'>
                                {`${fetchData.first_name} ${fetchData.last_name}`}
                              </span>
                              <p>{fetchData.department}</p>
                            </span>
                          </>
                        ) : (
                          <>
                            <span>
                              <span className='text-gray-800'>
                                {`${fetchData.first_name} ${fetchData.last_name}`}
                              </span>
                              <p>{fetchData.department}</p>
                            </span>
                          </>
                        )
                      }
                    </td>

                    <td className={`hidden px-3 py-4 text-sm text-gray-500 sm:table-cell ${fetchData.total_hrs.regular_hrs < 80 ? 'bg-red-600 text-white' : ''}`}>
                      {
                        fetchData.total_hrs.regular_hrs < 0 || fetchData.total_hrs.regular_hrs == null ? '0' : fetchData.total_hrs.regular_hrs
                      } hrs
                    </td>

                    <td className={`hidden px-3 py-4 text-sm text-gray-500 sm:table-cell 
                                 ${fetchData.total_hrs.overtime_hrs === fetchData.active_overtime?.hours ? 'bg-green-400 text-white' : ''}
                                 ${fetchData.total_hrs.overtime_hrs > 30 ? 'bg-yellow-500 text-white' : ''}`
                    }>
                      {
                        fetchData.total_hrs.overtime_hrs < 0 || fetchData.total_hrs.overtime_hrs == null ? '0' : fetchData.total_hrs.overtime_hrs
                      } hrs
                      <br/>
                      <div className='text-green-600'>
                        {fetchData?.active_overtime?.hours > 0 ? 
                         `${fetchData?.active_overtime?.hours} P:Hrs` : ''}
                      </div>
                    </td>

                    <td>
                      {fetchData.timesheet_expense && fetchData.timesheet_expense.length === 0 ? (
                        ('')
                      ) : (
                        <>
                          <ExpenseFileLocation expFile={`${fetchData.timesheet_expense.expense_file}`} />
                        </>
                      )}
                    </td>

                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {fetchData.timesheet_expense.checked_by ?
                        fetchData.timesheet_expense.checked_by : ''}
                    </td>

                    <td className={`hidden px-3 py-4 text-sm text-gray-500 sm:table-cell `}>
                      {fetchData.timesheet_expense.expense_amount ?
                        <CurrencyFormat value={fetchData.timesheet_expense.expense_amount} /> : ''}
                        <br/>
                      {fetchData.timesheet_expense.expense_comment && fetchData.timesheet_expense.expense_comment}
                    </td>

                    <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell max-w-[350px]">
                        {fetchData.timesheet_expense.admin_expense_comment && 
                        <Truncate text={fetchData.timesheet_expense.admin_expense_comment} 
                             handleNotesView={() => handleNotesView(fetchData?.timesheet_expense?.id)}
                          />}
                        { notesIdOpen === fetchData?.timesheet_expense?.id && (
                          <ModalWindow job={fetchData.timesheet_expense.admin_expense_comment} 
                          closeNotes={() => setNotesIdOpen(null)} activeButton={true}/>
                        )}
                    </td>

                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default TechTimesheetPage;
