// components/LogBooks.jsx
import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const LogBooks = ({ logbookstatus, setLogbookStatus, urlID, setShowCustomerEmailBox }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const logBookSetStatus = async (status) => {
    const bodyData = {
      status: 'logBookStatus',
      inspection_id: parseInt(urlID),
      logBookStatus: status
    };
    try {
      const response = await axios.post(`${apiUrl}/contracts_crud.php`, bodyData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data[0] === 'success') {
        setLogbookStatus(status);
        setShowCustomerEmailBox(true);
      } else {
        console.error('Error setting log book status:', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      } else {
        console.error('Unexpected error:', error);
      }
    }
  };

  return (
    <div className="mb-5">
      <p className="pl-4 font-bold">
        Is Building Report Updated? <span className="text-red-500 text-sm">(required)</span>
      </p>

      <div className="mt-2">
        <label className="flex items-center space-x-2 mb-2">
          <input
            type="radio"
            name="logbookstatus"
            checked={logbookstatus === 'Yes'}
            onChange={() => logBookSetStatus('Yes')}
          />
          <span>Yes</span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="radio"
            name="logbookstatus"
            checked={logbookstatus === 'No'}
            onChange={() => logBookSetStatus('No')}
          />
          <span>No</span>
        </label>
      </div>
    </div>
  );
};

export default LogBooks;
