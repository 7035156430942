import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import SearchBarCP from '../components/SearchBarCP';
import { CloseButton, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateFormat from '../components/DateFormat';
import LegendCP from '../components/LegendCP';
import SubMenu from '../components/SubMenu';
import ModalWindow from '../components/ModalWindow';
import ModalNotesSection from '../components/ModalNotesSection';
import Truncate from '../components/Truncate';
import { useUserData } from '../context/UserData';
import LoadingSpinner from '../components/LoadingSpinner';
import WipComments from '../components/ModalWipNotesSection';
import { LinkIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

const Engineering = () => {
  const [openNotesJobId, setOpenNotesJobId] = useState(null); 

  // <-- NEW: Pagination states
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  // Data states
  const [engineering, setEngineering] = useState([]);
  const [filteredEngineering, setFilteredEngineering] = useState([]);

 // compute pagination
  const totalPages = Math.ceil(filteredEngineering.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentContracts = filteredEngineering.slice(startIndex, endIndex);

  // NEW: keep the original “current” list
  const [originalEngineering, setOriginalEngineering] = useState([]);

  // UI states
  const [showAppoved, setShowApproved] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModalId, setOpenModalId] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);

// State to control the "Folder Link" modal
const [openFolderModalId, setOpenFolderModalId] = useState(null);

// State to store the link typed by the user
const [folderLink, setFolderLink] = useState('');


  // For notes submission
  const [bodyDataNotes, setBodyDataNotes] = useState({
                                                       status: 'submit_notes',
                                                       data: {notes: ''}
                                                      });
  // NEW: dropdown toggle: "current" or "all"
  const [projectMode, setProjectMode] = useState('current');

  const userData = useUserData();
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/engineering_crud.php`;

  useEffect(() => {
    fetchEngineering(); // load “current” data on mount
  }, []);

  // Fetch the initial (current) data list
  const fetchEngineering = async () => {
    try {
      const response = await axios.get(`${apiUrl}/engineering_crud.php`, {
        params: { getList: 'list' }
      });
      const data = response.data;
      if (data.error) {
        toast.error(data.error, { position: 'top-center' });
        setEngineering([]);
        setFilteredEngineering([]);
      } else if (Array.isArray(data)) {
        setEngineering(data);
        setFilteredEngineering(data);
        setOriginalEngineering(data); // Save the “current” data for revert
      } else {
        setEngineering([]);
        setFilteredEngineering([]);
      }
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch all projects (if user selects “All Projects”)
  const fetchAllProjects = async () => {
    try {
      // Adjust if your backend expects different params for “all projects”
      const response = await axios.get(`${apiUrl}/engineering_crud.php?getList=all`);
      if (Array.isArray(response.data)) {
        setEngineering(response.data);
        setFilteredEngineering(response.data);
      } else {
        toast.error('Unexpected data format for all projects', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error fetching all projects', error);
    }
  };

  // Handle the dropdown toggle
  const handleProjectModeChange = async (e) => {
    const mode = e.target.value;
    setProjectMode(mode);

    if (mode === 'all') {
      setLoading(true);
      await fetchAllProjects();
      setLoading(false);
    } else {
      // “current” => revert to original
      setEngineering(originalEngineering);
      setFilteredEngineering(originalEngineering);
    }
  };

  // Toggle the “Show Complete” button
  const toggleShowApproved = () => {
    setShowApproved(!showAppoved);
  };

  // Search logic
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setFilteredEngineering(engineering);
    } else {
      const filtered = engineering.filter(item =>
        (item.description && item.description.toLowerCase().includes(value.toLowerCase())) ||
        (item.job_site && item.job_site.toLowerCase().includes(value.toLowerCase())) ||
        (item.job_number && item.job_number.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredEngineering(filtered);
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = {
      status: 'search',
      data: { search: searchTerm }
    };

    try {
      const response = await axios.post(`${apiUrl}/engineering_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data && Array.isArray(response.data)) {
        setEngineering(response.data);
        setFilteredEngineering(response.data);
      } else {
        setEngineering([]);
        setFilteredEngineering([]);
        toast.error('not found ' + searchTerm, { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error searching active jobs', error);
    } finally {
      setLoading(false);
    }
  };

  // Handling notes
  const handleNotes = (var_test) => {
    setOpenModalId(var_test.idItem);
  };

  const handleChange = (event, id, user) => {
    bodyDataNotes.user_id = user.user_id;
    const { name, value } = event.target;

    setFilteredEngineering(prev => prev.map(engineer => {
      if (parseInt(engineer.id) === id) {
        const updatedEngineer = { ...engineer, [name]: value };
        bodyDataNotes.data = updatedEngineer;
        updatedEngineer.employee_name = `${user.first_name} ${user.last_name}`;
        return updatedEngineer;
      }
      
      return engineer;
    }));
  };

  // Complete
  const handleComplete = async (e) => {
    try {
      await axios.post(api, { status: 'submit_complete', id: e.idItem }, {
        headers: { 'Content-Type': 'application/json' }
      });
      toast.info('Saved!', { position: 'top-center' });
    } catch (error) {
      toast.error('Oops minor issue with the data.', { position: 'top-center' });
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', { position: 'top-center' });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: 'top-center' });
      }
    }
  };

  // Approve
  const handleApproval = async (e) => {
    try {
      const response = await axios.post(api, { status: 'submit_approval', id: e.idItem }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (Array.isArray(response.data) && response.data[0] === 'success') {
        toast.info('Saved!', { position: 'top-center' });
        setFilteredEngineering(items =>
          items.map(project => {
            if (project.id === e.idItem) {
              return { ...project, approved_paperwork: 1 };
            }
            return project;
          })
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', { position: 'top-center' });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: 'top-center' });
      }
    }
  };

  // Void or remove void
  const voidJobSubmit = async (id, currentVoidState) => {
    setLoading(true);
    const newVoidStatus = currentVoidState === 'void' ? '' : 'void';
    const bodyData = { status: 'void', data: { voidID: id, void: newVoidStatus } };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data === 'Success') {
        setFilteredEngineering(items =>
          items.map(project => {
            if (project.id === id) {
              return { ...project, job_status: newVoidStatus };
            }
            return project;
          })
        );
        toast.success('Saved!', { position: 'top-center' });
      } else {
        toast.error('error updating', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error saving project', error);
    } finally {
      setLoading(false);
    }
  };

  const getLinksForJob = (engineer) => {
    return [
      { name: 'Edit Job', click: handleNotes },
      { name: 'Complete', click: handleComplete },
      { name: 'Approve PW', click: handleApproval },
      {
        name: engineer.job_status === 'void' ? 'Remove void' : 'Void project',
        click: () => voidJobSubmit(engineer.id, engineer.job_status)
      },
    ];
  };

  const handleNotesView = (id) => {
    setNotesIdOpen(id);
  };

  const handleNoteChangeParent = (id, newNote) => {
    setFilteredEngineering((prev) =>
      prev.map((engineer) => {
        if (parseInt(engineer.id) === parseInt(openNotesJobId)) {
          // Make sure .notes is an array, then append newNote
          const updatedNotes = Array.isArray(engineer.notes)
            ? [...engineer.notes, newNote]
            : [newNote];

          return {
            ...engineer,
            notes: updatedNotes
          };
        }
        return engineer;
      })
    );
  };

  const handleSaveFolderLink = async (engineerId) => {
    try {
      // 1. Update local state
      setFilteredEngineering((prev) =>
        prev.map((eng) => {
          if (eng.id === engineerId) {
            // Add or update a property on the row, e.g. “folder_link”
            return { ...eng, folder_link: folderLink };
          }
          return eng;
        })
      );

      // 2. Optionally send to backend
      const saveBody = {
        status: 'save_folder_link',
        data: {
          id: engineerId,
          folder_link: folderLink,
        },
      };
      const response = await axios.post(api, saveBody, { 
        headers: { 'Content-Type': 'application/json' } 
      });
      if (response.data === 'success') {
        toast.success('Folder link saved.', { position: 'top-center' });
      } else {
        toast.error('Error saving folder link.', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error saving folder link', error);
      toast.error('Error saving folder link.', { position: 'top-center' });
    } finally {
      // 3. Close the modal
      setOpenFolderModalId(null);
    }
  };


  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-gray-500 mb-8">Engineering Projects</h1>
        <LegendCP color="fill-green-300" label="Complete" />
        <br />
        <br />

        {/* Controls row */}
        <div className="flex space-x-3 items-center mb-10">
          {/* Show/Hide Approved */}
          <button
            onClick={toggleShowApproved}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                       rounded-md shadow-sm text-white bg-red-400 hover:bg-red-700 
                       focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {showAppoved ? 'Show Complete' : 'Hide Complete'}
          </button>

          {/* Dropdown: Current vs. All */}
          <select
            value={projectMode}
            onChange={handleProjectModeChange}
            className="rounded-md border border-gray-300 py-2 px-4"
          >
            <option value="current">Current</option>
            <option value="all">All Projects</option>
          </select>

          {/* Search bar */}
          <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
        </div>

        {loading ? (
          <LoadingSpinner>Loading...</LoadingSpinner>
        ) : (
          <>
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr className="divide-x divide-gray-400">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2"
                >
                  ID/Edit
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Employee Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Proposal | Job Site | Eng. Cont.
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-[50px]"
                >
                  Approved PW
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-[50px]"
                >
                  P-Type
                </th>
                <th scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2"
                >
                  WIP
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Wip-Notes
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Notes
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {currentContracts
                .filter((eng) =>
                  showAppoved && eng.job_status === 'complete'
                    ? eng.approved_paperwork !== 1
                    : eng
                )
                .map((engineer, index) => (
                  <tr key={engineer.id}
                    className={`
                      ${engineer.job_status === 'void' ? 'line-through text-red-600' : ''} 
                      ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} 
                      ${engineer.job_status === 'complete' ? 'bg-green-300' : ''} divide-x`}>
                    {/* ID/Edit */}
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                      <SubMenu label={engineer.id} links={getLinksForJob(engineer)} idItem={engineer.id} />

                      {openModalId === engineer.id && (
                        <ModalWindow
                          job={
                            <ModalNotesSection
                              onChange={(e) => handleChange(e, engineer.id, userData?.users)}
                              api={api}
                              inside="engineering_notes"
                              val={engineer}
                              bodyData={bodyDataNotes}
                              redirectPath="/engineering/list"
                              closeNotes={() => setOpenModalId(null)}
                            />
                          }
                          closeNotes={() => setOpenModalId(null)}
                        />
                      )}
                      <br />
                      <dl className="font-normal lg:hidden">
                        <dd className="mt-1 truncate text-gray-700">{engineer.employee_name}</dd>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">{engineer.job_address}</dd>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">{engineer.date}</dd>
                      </dl>
                      {/* New button to open a "folder link" modal */}
                      <button
                        className="mt-2 px-2  bg-blue-500 text-white text-sm rounded"
                        onClick={() => {
                          setOpenFolderModalId(engineer.id);
                          // If you want to show any existing link in the input, do:
                          setFolderLink(engineer.folder_link || '');
                        }}
                      >
                        +Link
                      </button>
                      {openFolderModalId === engineer.id && (
                        <ModalWindow
                          job={
                            <div className="p-4">
                              <h2 className="text-lg font-semibold mb-2">Add/Edit Folder Link</h2>

                              <label htmlFor="folderLinkInput" className="block text-sm font-medium text-gray-700 mb-1">
                                Folder Link (file://… or network path)
                              </label>
                              <input
                                id="folderLinkInput"
                                type="text"
                                value={folderLink}
                                onChange={(e) => setFolderLink(e.target.value)}
                                className="w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                              />

                              <div className="mt-4 space-x-2">
                                <button
                                  className="px-3 py-1 bg-green-600 text-white rounded"
                                  onClick={() => handleSaveFolderLink(engineer.id)}
                                >
                                  Save
                                </button>
                                <button
                                  className="px-3 py-1 bg-gray-400 text-white rounded"
                                  onClick={() => setOpenFolderModalId(null)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          }
                          closeNotes={() => setOpenFolderModalId(null)}
                        />
                      )}
                      {engineer.folder_link && (
                        <div className='mt-2'>
                          <a
                            href={`${engineer.folder_link}`} // e.g. "file:///C:/some/path"
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-600 underline hover:text-blue-800"
                          >
                           Open Link
                          </a>
                          </div>
                        )}                   
                    </td>

                    {/* Employee Name */}
                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {engineer.employee_name}
                    </td>

                    {/* Proposal / Job Site / Eng. Cont. */}
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {engineer.job_number}
                      <br />
                      <span className="text-gray-400">{engineer.job_site}</span>
                      <br />
                      <span className="text-green-600 font-semibold">
                        {engineer.contract_engineer && engineer.contract_engineer}
                      </span>
                      <div>{engineer.description}</div>
                    </td>

                    {/* Approved Paperwork */}
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {
                        engineer.approved_paperwork === 1 ? 'Yes' : engineer.approved_paperwork === 2 ? 'Partial' : 'No'
                      }
                    </td>

                    {/* Paperwork Type */}
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {engineer.paperwork_type}
                    </td>

                    {/* Work in progress */}
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {engineer.paperwork}
                    </td>

                    {/* WIP - Notes */}
                    <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell max-w-[350px]">
                      <button 
                          className="text-indigo-600 hover:text-indigo-900" 
                          onClick={() => {
                            setOpenNotesJobId(engineer.id)
                          }}
                        >
                            <PencilSquareIcon className='h-5 w-5 text-gray-400' aria-hidden="true" />
                        </button>
                      {openNotesJobId === engineer?.id && (
                        <ModalWindow
                          job={
                            <WipComments
                              onChange={(e) => handleChange(e, engineer?.id)}
                              api={`${apiUrl}/wip_crud.php`}
                              inside={'notes'}
                              notes={engineer?.notes}
                              bodyData={bodyDataNotes}
                              val={''}
                              id={engineer?.project_id}
                              handleNoteChangeParent={handleNoteChangeParent}
                              redirectPath={'/wip_list/aj'}
                              closeNotes={() => setOpenNotesJobId(null)}
                            />
                          }
                          closeNotes={() => setOpenNotesJobId(null)}
                          activeButton={true}
                        />
                      )}
                    </td>

                    {/* Notes */}
                    <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell max-w-[350px]">
                      {engineer.engineering_notes && (
                        <Truncate
                          text={engineer.engineering_notes}
                          handleNotesView={() => handleNotesView(engineer.id)}
                        />
                      )}
                      {notesIdOpen === engineer.id && (
                        <ModalWindow
                          job={engineer.engineering_notes}
                          closeNotes={() => setNotesIdOpen(null)}
                          activeButton={true}
                        />
                      )}
                    </td>

                    {/* Date */}
                    <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell">
                      <DateFormat date={engineer.date} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
      
          {/* <-- NEW: pagination controls */}
          <div className="flex justify-center items-center mt-4 space-x-2">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-3 py-1 border rounded disabled:opacity-50"
            >
              Prev
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="px-3 py-1 border rounded disabled:opacity-50"
            >
              Next
            </button>

            {/* optional: change page size */}
            <select
              className="ml-4"
              value={pageSize}
              onChange={(e) => {
                setPageSize(+e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </select>
          </div>            
          </>          
        )}
      </div>
    </>
  );
};

export default Engineering;
