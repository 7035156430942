import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  CurrencyDollarIcon,
  ClipboardDocumentCheckIcon,
  PresentationChartBarIcon,
  WrenchScrewdriverIcon,
  CogIcon,
  QueueListIcon,
  XCircleIcon,
  BuildingOfficeIcon,
  TagIcon,
  CpuChipIcon,
} from '@heroicons/react/24/outline'
import { sub } from 'date-fns'
import { NavLink } from 'react-router-dom'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SideNav = () => {

  const navigation = [
  { name: 'Office', 
    icon: BuildingOfficeIcon,
    submenu: [          
              {name: 'Techs Timesheets', 
               href: '/tech_timesheet', 
               current: false
              },
              {name: 'Office Overtime Timesheets', 
               href: '/office_timesheet', 
               current: false,
              },
              {name: 'Overtime Dashboard', 
               href: '/overtime_dashboard', 
               current: false, 
              },
              {name: 'Attendance', 
               href: '/attendance/Admin', 
               current: false,
              },
              {name: 'All Staff', 
               href: '/employee_list', 
               current: false 
              },
              {name: 'Vacation Calendar', 
               href: '/office_calendar', 
               current: false
              },
              {name: 'Vacation Request', 
               href: '/vacation_req', 
               current: false
              },

            ]
  },
    { name: 'Reports', 
    icon: ChartPieIcon, 
    submenu: [{ name: 'Sales Report', 
               href: '/sales_report', 
               current: false, 
              },
              {name: 'PTO Report', 
               href: '/pto_report', 
               current: false, 
              },
              {name: 'Verify Check in/out', 
               href: '/Vcheckinout', 
               current: false,
              },              
              {name: 'Recurring Service', 
               href: '/service_report', 
               current: false, 
              },
              // {name: 'Timesheets Report', 
              //  href: '#', 
              //  current: false, 
              // },
              {name: 'Vacations YTD', 
               href: '/vacation_ytd', 
               current: false
              },
            ]
  },
  { name: 'Accounting', 
    icon: CurrencyDollarIcon, 
    submenu: [{ name: 'Credit Hold Projects', 
               href: '/credit_hold', 
               current: false, 
              },
              {name: 'Vendors', 
               href: '/accounting', 
               current: false 
              },
              {name: 'Invoice History', 
               href: '/inv_history/list', 
               current: false 
              },
              // {name: 'Maintenance Billing', 
              //  href: '#', 
              //  current: false,  
              // },
              {name: 'Payroll', 
               href: '/payroll_page', 
               current: false 
              },
              {name: 'Invoice Request(F.List)', 
               href: '/invoice_update', 
               current: false 
              }
              // {name: 'COT Billing', 
              //  href: '/cot_billing', 
              //  current: false,  
              // }
            ]
  },

  { name: 'COT Contracts', 
    icon: CpuChipIcon,
    submenu: [{ name: 'COT Dashboard', 
               href: '/cot_billing', 
               current: false, 
              },
              // {name: 'Time Tracking', 
              //  href: '#', 
              //  current: false, 
              // },
              // {name: 'Estimator', 
              //  href: '#', 
              //  current: false, 
              // },
              {name: 'New Contract', 
               href: '/new_cot/new', 
               current: false,  
              }
            ]
  },  

  { name: 'Maintenance', 
    icon: ClipboardDocumentCheckIcon,
    submenu: [{ name: 'Maintenance Dashboard', 
               href: '/maintenance', 
               current: false, 
              },
              // {name: 'Time Tracking', 
              //  href: '#', 
              //  current: false, 
              // },
              // {name: 'Estimator', 
              //  href: '#', 
              //  current: false, 
              // },
              {name: 'Inspection List', 
               href: '/maintenance_inspections', 
               current: false,  
              }
            ]
  },

  { name: 'FDNY', 
    icon: ClipboardDocumentCheckIcon,
    submenu: [{ name: 'FDNY Inspections', 
               href: '/fdny_inspection', 
               current: false, 
              },
              // {name: 'Time Tracking', 
              //  href: '#', 
              //  current: false, 
              // },
              // {name: 'Estimator', 
              //  href: '#', 
              //  current: false, 
              // },
              // {name: 'Inspection List', 
              //  href: '#', 
              //  current: false,  
              // }
            ]
  },

  { name: 'Purchasing', 
    icon: TagIcon,
    submenu: [{ name: 'Delivery Tracking', 
               href: '/delivery_tracking', 
               current: false, 
              },
              { name: 'Inventory Dashboard', 
               href: '/inventory_dashboard', 
               current: false, 
              }
            ]
  },

  { name: 'Sales', 
    icon: PresentationChartBarIcon,
    submenu: [{name: 'Job Estimator', 
              //  href: '#', 
              href: '/estimate_pro/new', 
               current: false,  
              },  
              {name: 'Proposal List', 
               href: '/proposal_list', 
               current: false 
              },
              {name: 'Estimate List', 
              //  href: '#', 
               href: '/estimate', 
               current: false,  
              },
              {name: 'Active Projects', 
               href: '/wip_list/aj', 
               current: false 
              }]
  },

  { name: 'Service', 
    icon: WrenchScrewdriverIcon,
    submenu: [
              { name: 'Service Proposal', 
               href: '/service_proposal', 
               current: false,
                
              },
              { name: 'Service Dashboard', 
               href: '/service_dashboard', 
               current: false,
                
              },
              {name: 'On Call List', 
               href: '/on_call', 
               current: false,
                
              },
              {name: 'Service Part List', 
               href: '/service_pl', 
               current: false,
                
              }
            ]
  },

  { name: 'Engineering', 
    icon: CogIcon,
    submenu: [{ name: 'Projects', 
               href: '/engineering/list', 
               current: false
              },
              {name: 'Data-Sheets', 
               href: '#', 
               current: false,
                
              },
              {name: 'Building Codes',
               href: '#', 
               current: false,
               
              }
            ]
  },

  { name: 'Miscellaneous', 
    icon: QueueListIcon,
    submenu: [{ name: 'Customers', 
               href: '/customers', 
               current: false
              },
              {name: 'Job#Gen', 
               href: '/job_gen', 
               current: false 
              },
              {name: 'Send Code', 
               href: '#', 
               current: false 
              },
              {name: 'Code List', 
               href: '#', 
               current: false 
              }
            ]
  },
]

return (
    <div className='divide-y'>
      {navigation.map((item) => (
        <div key={item.name} className="group">
          <span className={classNames('group flex items-center px-2 py-2 text-sm font-medium rounded-md')}>
            <item.icon className={classNames(item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-green-700','mr-3 flex-shrink-0 h-6 w-6')}
              aria-hidden="true"/>
            {item.name}
          </span>
          {item.submenu && (
              <div className="ml-4">
                <ul className='list-outside list-disc ml-6'>
                  {item.submenu.map((sub) => (
                    <li key={sub.name}>
                      <NavLink to={sub.href} className={classNames(
                          sub.current ? 'font-bold' : 'font-normal text-gray-500',
                                                      'hover:text-white px-2 py-1 block text-sm',
                                                      'hover:bg-green-500 rounded-lg'

                        )}>
                         
                        <span className={`${sub.href === '#' ? 'text-red-600' : ''}`}>{sub.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )
          }
        </div>
      ))}
    </div>
  );
}

export default SideNav;