import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import DateFormat from '../components/DateFormat';
import { useUserData } from '../context/UserData';
import { useNavigate, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeadingCp from '../components/HeadingCp';
import MonthTabs from '../components/MonthTabs';
import Truncate from '../components/Truncate';
import { CheckBadgeIcon, DocumentCheckIcon } from '@heroicons/react/24/outline';
import SubMenu from '../components/SubMenu';
import LegendCP from '../components/LegendCP';
import SearchBarCP from '../components/SearchBarCP';
import ModalWindow from '../components/ModalWindow';
import { PhotoIcon } from '@heroicons/react/24/solid';
import Badge from '../components/Badge';
import FileLocation from '../components/FileLocation';

const InspectionsPage = () => {
  const [followupId, setFollowupId] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  const [inspectionNotesIdOpen, setInspectionNotesIdOpen] = useState(null);
  const [inspections, setInspections] = useState([]);
  const [filterInspection, setFilterInspection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(true);

  const [searchTerm, setSearchTerm] = useState('');
  const [billingFilter, setBillingFilter] = useState('all'); // new state for billing filter

  const userData = useUserData();
  const navigate = useNavigate();

  const [currentMonth, setCurrentMonth] = useState(() => {
    const date = new Date();
    return String(date.getMonth() + 1).padStart(2, '0');
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/contracts_crud.php`;
  const appRoot = process.env.REACT_APP_ROOT_URL;

  var expFileUrl = `${appRoot}maintenance_files/`;

  // State for Google Drive images
  const [imagesModalOpen, setImagesModalOpen] = useState(false);
  const [driveImages, setDriveImages] = useState([]);

  //file upload state
  const [file, setFile] = useState(null);
  const [selectGenFile, setSelectGenFile] = useState(null);
  const [isModalFileGenOpen, setIsModalFileGenOpen] = useState(false);

  // Define your month tabs
  const months = [
    { name: 'All', value: 'all' },
    { name: 'January', value: '01' },
    { name: 'February', value: '02' },
    { name: 'March', value: '03' },
    { name: 'April', value: '04' },
    { name: 'May', value: '05' },
    { name: 'June', value: '06' },
    { name: 'July', value: '07' },
    { name: 'August', value: '08' },
    { name: 'September', value: '09' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' },
  ];

  // Fetch inspections on initial load or when month changes
  useEffect(() => {
    fetchInspections(noAccess);
  }, [noAccess]);

  // Whenever inspections, searchTerm, or billingFilter changes, apply filters
  useEffect(() => {
    applyFilters(inspections, searchTerm, billingFilter);
  }, [inspections, searchTerm, billingFilter]);

  // Combine search & billing filters
  const applyFilters = (allInspections, search, billing) => {
    let filtered = [...allInspections];

    // 1) Search filter
    if (search) {
      filtered = filtered.filter((insp) =>
        (insp.job_site_address && insp.job_site_address.toLowerCase().includes(search.toLowerCase())) ||
        (insp.customer_name && insp.customer_name.toLowerCase().includes(search.toLowerCase())) ||
        (insp.contract_type && insp.contract_type.toLowerCase().includes(search.toLowerCase()))
      );
    }

    // 2) Billing filter
    //  Make sure to convert billing_complete to number if it’s stored as a string
    if (billing === 'complete') {
      filtered = filtered.filter((insp) => insp.status === 'Completed' && Number(insp.billing_complete) === 1);
    } else if (billing === 'incomplete') {
      filtered = filtered.filter((insp) => insp.status === 'Completed' && Number(insp.billing_complete) === 0);
    }

    setFilterInspection(filtered);
  };

  const fetchInspections = async (selectedMonth = currentMonth) => {
    setLoading(true);
    try {
      const response = await axios.get(api, {
        params: {
          getInspections: 'all',
          month: selectedMonth,
        },
      });
      if (response.data[0]) {
        setInspections(response.data[0]);
        if(response.data[1]){
          setNoAccess(false);
        }
      } else {
        toast.info('no inspections', {
          position: 'top-center',
        });
      }
    } catch (error) {
      // Handle errors
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error('Access denied.', {
          position: 'top-center',
        });
      } else {
        toast.error('Error fetching inspections', {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (monthValue) => {
    setCurrentMonth(monthValue);
    fetchInspections(monthValue);
  };

  // Toggle billing_status between 'ready' and 'not_ready'
  const handleReady = async (inspection_id, billingStatus, status) => {
    if (status === 'pending') {
      toast.info('Inspection is pending', { position: 'top-center' });
      return;
    }
    try {
      const response = await axios.post(
        api,
        {
          status: 'readyBilling',
          data: {
            inspection_id: inspection_id,
            billing_status: billingStatus,
          },
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data.success) {
        toast.success('updated!', { position: 'top-center' });
        fetchInspections();
      } else {
        toast.error(response.data.error || 'Error updating', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error updating billing', error);
      toast.error('Error updating billing', { position: 'top-center' });
    }
  };

  // Toggle billing_complete between 1 and 0
  const handleBillingComplete = async (inspection_id, billingComplete) => {
    billingComplete = billingComplete === 1 ? 0 : 1;

    try {
      const response = await axios.post(
        api,
        {
          status: 'billingComplete',
          data: {
            inspection_id: inspection_id,
            billing_complete: billingComplete,
          },
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data.success) {
        toast.success('updated!', { position: 'top-center' });
        fetchInspections();
      } else {
        toast.error(response.data.error || 'Error updating', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error updating billing', error);
      toast.error('Error updating billing', { position: 'top-center' });
    }
  };

  //Handler to open the file upload modal
  const handleGenUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    
    const formData = new FormData();
    formData.append('status', 'submit_gen_file');
    formData.append('id', selectGenFile); // Contract ID
    formData.append('file', file);
    try {
      const response = await axios.post(`${apiUrl}/inspection_file_upload.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });

        // Update the state with the new file information
        setFilterInspection(prevItems =>
        prevItems.map(item => {
          return item.id === selectGenFile ? { ...item, invoiced_file:{...item.invoiced_file, invoiced_file: response.data[0]}}: item
        } ));

        setFile(null);
        setIsModalFileGenOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
      //console.error('Error uploading file', error);
    }
  };  

  //handle file change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf'];
    const maxSize = 10 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only PDF is allowed.", { position: 'top-center' });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };  

  const voidContractSubmit = (contractId) => {
    // ...
  };

  // Sub-menu items for each contract
  const getLinksForContract = (contract) => {
    const links = [];
    // Show Billable link only if status is 'Completed'
    if (contract.status === 'Completed') {
      if(contract.billing_status === 0 || contract.billing_status === null){ 
      links.push({
        name: contract?.billing_status ? 'Remove Billable' : 'Billable',
        click: () =>
          handleReady(
            contract.inspection_id,
            contract.billing_status ? 'not_ready' : 'ready',
            contract.status
          ),
      });
      }
    }

    // If the contract is 'ready' for billing, show "Done" or "Pending"
    if (contract.billing_status === 'ready' && noAccess === false) {
      links.push({
        name: contract?.billing_complete === 1 ? 'Not Invoiced' : 'Invoiced',
        click: () => handleBillingComplete(contract.inspection_id, contract.billing_complete ? 1 : 0),
      });

    links.push({
      name: 'Upload Invoice',
      click: () => {setIsModalFileGenOpen(true); setSelectGenFile(contract.inspection_id); }
      // click: () => handleFile(contract.inspection_id),
    });      
    }

    return links;
  };

  // Update local state whenever user types in the search input
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // to override the local filtering. Could also rely 100% on local filter if your dataset is small.
  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = {
      status: 'searchInspections',
      data: { search: searchTerm },
    };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data && Array.isArray(response.data)) {
        setInspections(response.data);
        // The useEffect will run applyFilters and update filterInspection
      } else {
        setFilterInspection([]);
        toast.error('No results found for: ' + searchTerm, { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error searching contracts', error);
      toast.error('Error searching contracts', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };

  const handleNotesView = (id) => {
    setNotesIdOpen(id);
  };

  const handleInspectionNotesView = (id) => {
    setInspectionNotesIdOpen(id);
  };

  // State for Google Drive images
  const handleViewImages = async (inspection) => {
      // 1) Check if we have an array of folder references
    if (!inspection.inspection_images || !inspection.inspection_images.length) {
      toast.error('No folders for this inspection');
      return;
    }

    try {
      let allFiles = [];
      
      // 2) Loop over each item in inspection_images
      for (const folderRow of inspection.inspection_images) {
        const folderId = folderRow.image_name;  // the actual Drive folder ID

        // 3) Call the backend to list files in that folder
        const response = await axios.get(`${apiUrl}/drive_folder.php`, {
          params: {
            status: 'listFiles',
            folderId: folderId
          }
        });

        if (response.data.success && Array.isArray(response.data.files)) {
          // Merge these files into allFiles
          allFiles = [...allFiles, ...response.data.files];
        } else {
          console.warn(`No success for folderId=${folderId}`, response.data);
        }
      }

      // 4) Once all calls are done, store combined files in driveImages
      setDriveImages(allFiles);
      setImagesModalOpen(true);
    } catch (error) {
      console.error('Error listing images across multiple folders:', error);
      toast.error('Error listing images', { position: 'top-center' });
    }
  };

  return (
    <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
      <HeadingCp label="Inspections" />

      {/* Month Tabs */}
      <MonthTabs months={months} currentMonth={currentMonth} onMonthChange={handleMonthChange} />

      <br />
      <LegendCP color="fill-green-300" label="Ready For Billing" />
      <br />
      <br />

      <div className="flex space-x-3 items-center mb-10">
        <button
          onClick={() => navigate(`/maintenance`)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                    rounded-md shadow-sm text-white bg-green-600 hover:bg-green-400 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
        >
          Contracts
        </button>

        <button
          onClick={() => navigate('/inspection_calendar')}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                    rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-400 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
        >
          Scheduler
        </button>
 
        {/* New billing filter dropdown */}
        <select
          className="border px-2 py-1 rounded"
          value={billingFilter}
          onChange={(e) => setBillingFilter(e.target.value)}
        >
          <option value="all">All</option>
          <option value="complete">Invoiced</option>
          <option value="incomplete">Not Invoiced</option>
        </select>

        {/* Search Bar */}
        <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
      </div>

      {loading ? (
        <LoadingSpinner>Loading...</LoadingSpinner>
      ) : (
        <>
        <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
          <thead>
            <tr className="divide-x divide-gray-400">
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">ID</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Tech | Inspection site | Customer
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Schedule Contact</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Report /Img</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Scheduled Date</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Actual Inspection Date
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Notes</th>
            </tr>
          </thead>
          <tbody>
            {filterInspection?.map((inspection, index) => {
              // Highlight row if billing_status === 'ready'
              const rowClassName = () => {
                if (inspection.billing_status === 'ready') {
                  return 'bg-green-100 divide-x';
                } else {
                  return `${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`;
                }
              };

              return (
                <tr key={inspection.inspection_id} className={rowClassName()}>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <div className="text-sm text-gray-400">
                      <SubMenu
                        label={inspection.inspection_id}
                        links={getLinksForContract(inspection)}
                        idItem={inspection.inspection_id}
                      />

                      {inspection.billing_complete === 1 && (
                        <span>
                          <CheckBadgeIcon className="w-5 text-blue-700 font-extrabold" />
                        </span>
                      )}
                    </div>
                  </td>

                  {/* tech | inspection site | customer  */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {Array.isArray(inspection.followup_issues) && inspection.followup_issues.length > 0 && (
                      <div className='-mt-2 float-end hover:cursor-pointer hover:bg-yellow-700' onClick={() => setFollowupId(inspection.followup_issues)}>
                        <Badge feed={'Follow-up'} color={'yellow'}/>
                      </div>
                    )}
                    
                    <div className="text-green-700">
                      by: {inspection.lead_tech_first_name} {inspection.lead_tech_last_name}
                    </div>
                    <span className="font-bold text-black">
                      {inspection.job_site_address}
                      {inspection.job_site_city && ' ' + inspection.job_site_city + ', '}
                      {inspection.job_site_state && ' ' + inspection.job_site_state + ' '}
                      {inspection.job_site_zip && ' ' + inspection.job_site_zip}
                    </span>
                    <p className="text-sm text-gray-400">
                      {inspection.customer_name}
                    </p>
                  </td>

                  <td className="px-3 py-4 text-sm text-gray-500">
                    {inspection.contact_name}
                    <p>{inspection.contact_phone && ' ' + inspection.contact_phone + ', '}</p>
                    <p>{inspection.contact_email && ' ' + inspection.contact_email + ' '}</p>
                  </td>

                  {/* file */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <span className="text-gray-700 p-1 flex items-center">
                      {inspection.customer_signature && (
                        <NavLink
                          to={`/maintenance_ticket/${inspection.inspection_id}`}
                          target="_blank"
                          className={"flex items-center"}
                          rel="noopener noreferrer"
                        >
                          <span><DocumentCheckIcon className="w-5 text-green-600 font-bold" /></span>
                          <span className='text-green-600 '>Report</span>
                        </NavLink>
                      )}
                    </span>

                    <span>
                      {/* if you store multiple images in Google Drive, show a “View Images” button */}
                      {inspection.inspection_images && (
                        <button
                          onClick={() => handleViewImages(inspection)}
                          className="flex items-center gap-1 text-blue-700 hover:underline"
                        >
                          <PhotoIcon className="w-5 text-blue-600 font-bold" />
                          Images
                        </button>
                      )}
                    </span>
                    <span className='flex items-center py-1'>
                    <FileLocation expFile={inspection.invoiced_file} where="maintenance_files" />
                     Invoiced
                    </span>
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <DateFormat date={inspection.scheduled_due_date} />
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <DateFormat date={inspection.actual_inspection_date} />
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {inspection.status}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {inspection.tech_notes && (
                      <span
                        className="flex items-center mb-1 hover:cursor-pointer text-indigo-700"
                        onClick={() => setNotesIdOpen(inspection.inspection_id)}
                      >
                        <DocumentCheckIcon className="w-5" /> Tech
                      </span>
                    )}
                    {notesIdOpen === inspection.inspection_id && (
                      <ModalWindow
                        job={inspection.tech_notes}
                        closeNotes={() => setNotesIdOpen(null)}
                        activeButton={true}
                      />
                    )}
                    {inspection.inspection_notes && (
                      <span
                        className="flex items-center mb-1 hover:cursor-pointer text-orange-700 mt-2"
                        onClick={() => setInspectionNotesIdOpen(inspection.inspection_id)}
                      >
                        <DocumentCheckIcon className="w-5" /> Inspection
                      </span>
                    )}
                    {inspectionNotesIdOpen === inspection.inspection_id && (
                      <ModalWindow
                        job={inspection.inspection_notes}
                        closeNotes={() => setInspectionNotesIdOpen(null)}
                        activeButton={true}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>       
          {imagesModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white rounded p-4 max-w-3xl w-full">
                <h2 className="text-lg font-bold mb-3">Images for Inspection</h2>
                <button
                  className="text-red-500 float-right"
                  onClick={() => setImagesModalOpen(false)}
                >
                  Close
                </button>
                <br />
                <div className="grid grid-cols-3 gap-2 mt-8">
                  {driveImages?.map((file) => (
                    <div key={file.id} className="border p-2">
                      <p className="text-sm font-medium">{file.name}</p>
                      {/* If it's an image, show a preview */}
                      {/* https://drive.google.com/uc?export=view&id=ACTUAL_FILE_ID */}
                      {file?.id && file?.mimeType.startsWith('image/') ? (
                        <>            
                        <NavLink to={file?.webViewLink} target="_blank">
                        <img
                          // src={`https://drive.google.com/uc?export=view&id=${file?.id}`}
                          src={`https://drive.google.com/thumbnail?id=${file?.id}&sz=w1000`}
                          // src={`https://drive.google.com/uc?export=view&id=${file?.id}`}
                          alt="view file"
                          className="mt-2 w-full h-auto object-cover">          
                          </img>
                          </NavLink>         
                        </>
                      ) : (
                        <a
                          href={file.webViewLink}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-500 underline"
                        >
                          Open file
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {followupId && (
            <ModalWindow
              job={
                <ul>
                  {followupId.map((issue) => (
                    <li key={issue.id} className="text-sm text-gray-500">
                      {/* Example fields to display: */}
                      {issue.device} – {issue.device_location} – {issue.issue}
                    </li>
                  ))}
                </ul>
              }
              closeNotes={() => setFollowupId(null)}
              activeButton={true}
            />
          )}

      {/* file upload */}
      {isModalFileGenOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
          <div className="bg-white p-8 rounded-md shadow-md min-w-[500px]">
            <h2 className="text-xl font-semibold mb-4">Upload Invoice</h2>
            <form onSubmit={handleGenUploadFile}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Select a PDF file (Max size 2MB)</label>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  className="mt-2"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsModalFileGenOpen(false)}
                  className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                >
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
      )}             
        </>
      )}
    </div>
  );
};

export default InspectionsPage;