import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SubmitButton from '../components/SubmitButton';
import CurrencyFormat from '../components/CurrencyFormat';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopTabs from '../components/TopTabs';

//froms imports
import FormHeader from '../components/FormHeader';
import FormVarH   from '../components/FormVarH';
import FormPhone  from '../components/FormPhone';
import FormEmail  from '../components/FormEmail';
import FormVarTH  from '../components/FormVarTH';
import FormCSZ    from '../components/FormCSZ';
import FormVarF   from '../components/FormVarF';
import FormText   from '../components/FormText';


  const CustomerBillToEdit = () => {

  const navigate = useNavigate();
  const { customerID, billtoID, key } = useParams();
  const [billto, setBillTo] = useState({
                                        id : '',
                                        customer_id : '',
                                        name : '',
                                        address_1 : '',
                                        address_2 : '',
                                        city : '',
                                        state : '',
                                        zip_code : '',
                                        country : '',
                                        alt_phone : '',
                                        alt_phone_contact : '',
                                        alt_phone_extension : '',
                                        main_email : '',
                                        cc_email : '',
                                        primary_contact : '',
                                        main_phone : '',
                                        main_phone_contact : '',
                                        main_phone_extension : '',
                                        work_phone : '',
                                        work_phone_contact : '',
                                        work_extension : '',
                                        mobile : '',
                                        mobile_contact : '',
                                        mobile_extension : '',
                                        fax : '',
                                        note : ''
                                       });
  const [customer, setCustomer] = useState([]);

  
  const customerList = { name: 'Customer List', href: '/customers', current: false };
  const customerTab = { name: 'Customer', href: `/customer/edit/${customerID}`, current: false };
  const billtoTab = { name: 'Bill to edit',  current: true };
  const billToAdd = { name: '+ Add Bill to', href: `/customer_billto_edit/${customerID}/${billtoID}/new`,  current: false };
  const billToAddNew = { name: '+ Add Bill to', href:"", current: true };
  const billToList = { name: 'Bill to list', href: `/customer_billto/edit/${customerID}`, current: false };
 
  const tabs = key === 'new' 
  ? [customerList, customerTab, billToList, billToAddNew] 
  : [customerList, customerTab, billToList, billtoTab, billToAdd]; //edit                                

  useEffect( () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const fetchCustomer = async () =>{
                                                 // getBilltoDetail=new&&customer_id=${customerID}`
      if(key !== 'new')  {
          try {                                                 
                const res = await fetch(`${apiUrl}/customer_crud.php?getBilltoDetail=edit&&billto_id=${billtoID}`, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                  }
                });
                
          if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
          }
          const data = await res.json();
          //console.log(data.billto.id);
          if(data.error){
            navigate('/customers')
            toast.error(data.error, {
              position:'top-center'
            });
          }
          setBillTo(data.billto);
          setCustomer(data.customer);
          
          } catch(error) {
            //console.log('Error fetching data', error);
          } finally {
            // setLoading(false);
          }
      } else {
        //console.log('not new');
        
          try {
                const res = await fetch(`${apiUrl}/customer_crud.php?getBilltoDetail=new&&customer_id=${customerID}`, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                  }
                });
                
          if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
          }
          const data = await res.json();
          //console.log(data);
          
          if(data.error){
            // navigate('/customers')
            toast.error(data.error, {
              position:'top-center'
            });
          }
          setCustomer(data.customer);
          } catch(error) {
            //console.log('Error fetching data', error);
          } finally {
            // setLoading(false);
          }
      }
    }

    fetchCustomer();
  }, [customerID, billtoID, navigate])

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setBillTo(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));

    //console.log(customer);//delete
  };

  const handleSubmit = async (event) => {
      event.preventDefault();  // Prevent the default form submit behavior
      const apiUrl = process.env.REACT_APP_API_URL;

      const bodyData = {
          status: 'billto_submit',
          key: key,
          customer_id: customerID,
          data: billto
      };
      try {
          const response = await axios.post(`${apiUrl}/customer_crud.php`, bodyData, {
              headers: { 'Content-Type': 'application/json' }
          });
          const data = response.data;  
          toast.info('Saved!', {
            position:'top-center'
          })
          navigate(`/customer_billto/edit/${customerID}`);
      } catch (error) {
          if (error.response) {
              //console.error('Error submitting customer', error.response.data);
          } else if (error.request) {
              //console.error('Error submitting proposal', error.request);
          } else {
              //console.error('Error', error.message);
          }
      }
  };

  const deleteBillto_id = async(event) => {
    event.preventDefault();
    const apiUrl = process.env.REACT_APP_API_URL;
    const bodyData = {
        status: 'deleteBillto',
        key: key,
        billto_id: billtoID,
        data: billto
    };

      try {
          const response = await axios.post(`${apiUrl}/customer_crud.php`, bodyData, {
              headers: { 'Content-Type': 'application/json' }
          });
          const data = response.data;  
          //console.log(data);
          
          toast.info('Saved!', {
            position:'top-center'
          })
           navigate(`/customers`);
      } catch (error) {
          if (error.response) {
              //console.error('Error submitting customer', error.response.data);
          } else if (error.request) {
              //console.error('Error submitting proposal', error.request);
          } else {
              //console.error('Error', error.message);
          }
      }

  }

  return (

        <>
        <div className="flex items-center justify-center min-h-screen bg-gray-100 py-5">
        <form onSubmit={handleSubmit} className='w-[800px] px-2 bg-white border rounded-lg py-8 px-8'>
          <div className="space-y-12">
          <FormHeader type={'Edit'} what={'Customer bill-to'} />
          
          <TopTabs tabs={tabs} />
          <div className="border-b border-gray-900/10 pb-12 ">
              <h6 className="text-base font-semibold leading-7 text-gray-600">
                <NavLink to={`/customer/edit/${customer.id}`}>
                <span className='text-blue-500'>Customer: {customer.customer_name}</span>
                </NavLink>
              </h6>
              <br/>
                {  key !== 'new' ? 
                  <button
                    type="button"
                    onClick={deleteBillto_id}
                    className="rounded bg-red-600 px-2 py-1 text-sm font-semibold text-white shadow-sm
                    hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                    focus-visible:outline-indigo-600">
                    Delete bill-to
                  </button>
                :''}
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {/* put code below */}
                <FormVarH label={'Company Name'} inside={'name'} val={billto.name} onChange={handleChange} required={false}/>
                <FormVarH label={'primary_contact'} inside={'primary_contact'} val={billto.primary_contact} onChange={handleChange} required={false}/>
                <FormPhone label={'Main phone'} inside={'main_phone'} val={billto.main_phone} onChange={handleChange}  required={false}/>
                <div className="sm:col-span-2">
                  <label htmlFor={'main_extension'} className="block text-sm font-medium leading-6 text-gray-900">
                    {'Ext'} 
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={'main_extension'}
                      id={'main_extension'}
                      maxLength="10"
                      minLength="1"
                      value={billto.main_extension || ''} 
                      onChange={handleChange}
                      autoComplete={'main_extension'}
                      className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>               
                <FormPhone label={'Fax (number only)'} inside={'fax'} val={billto.fax} onChange={handleChange}         required={false}/>
                <FormEmail label={'Email'} inside={'main_email'} val={billto.main_email} onChange={handleChange} />
                <FormVarTH label={'Address'} inside={'address_1'} val={billto.address_1} onChange={handleChange} />
                <FormCSZ city={billto.city} state={billto.state} zip={billto.zip_code} onChange={handleChange} />
                
                {/* contact sections */}
                  <div className="relative col-span-full py-4">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="bg-white px-2 text-sm text-gray-500">Extra contact</span>
                    </div>
                  </div>    
                {/* contact sections */}
                <FormVarF label={'Work Contact'} inside={'work_phone_contact'} val={billto.work_phone_contact} onChange={handleChange} required={false}/>
                <FormPhone label={'work_phone'} inside={'work_phone'} val={billto.work_phone} onChange={handleChange} required={false}/>
                <div className="sm:col-span-2">
                <label htmlFor={'work_extension'} className="block text-sm font-medium leading-6 text-gray-900">
                  {'Ext'} 
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name={'work_extension'}
                    id={'work_extension'}
                    maxLength="10"
                    minLength="1"
                    value={billto.work_extension || ''} 
                    onChange={handleChange}
                    autoComplete={'work_extension'}
                    className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                </div>                 
                <FormVarF label={'Mobile Contact'} inside={'mobile_contact'} val={billto.mobile_contact} onChange={handleChange} required={false}/>
                <FormPhone label={'Mobile'} inside={'mobile'} val={billto.mobile} onChange={handleChange} required={false}/>
                <div className="sm:col-span-2">
                <label htmlFor={'mobile_extension'} className="block text-sm font-medium leading-6 text-gray-900">
                  {'Ext'} 
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name={'mobile_extension'}
                    id={'mobile_extension'}
                    maxLength="10"
                    minLength="1"
                    value={billto.mobile_extension || ''} 
                    onChange={handleChange}
                    autoComplete={'mobile_extension'}
                    className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                </div>                 
                <FormText label={'CC emails add (;) at the end of every email'} inside={'cc_email'} val={billto.cc_email} onChange={handleChange} required={false}/>
                <FormText label={'Bill-to Notes'} inside={'description'} val={billto.note} onChange={handleChange} required={false}/>

              {/* put code above this line */}
            </div>
          </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <NavLink to={`/customer_billto/edit/${customerID}`}>
              <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                Cancel
              </button>
            </NavLink>
            <SubmitButton disabled={!billto.address_1 || !billto.city || !billto.state || !billto.zip_code } children={'Submit'} /> 
          </div>
        </form>
        </div>
        </>

  )
}

export default CustomerBillToEdit