// pages/InspectionDetails.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InspectionType from './InspectionType';
import DevicesTested from './DevicesTested';
import TechNotes from './TechNotes';
import AdditionalTech from './AdditionalTech';
import LogBooks from './LogBooks';
import CustomerEmail from './CustomerEmail';
import HeadingCp from '../components/HeadingCp';
import Accordion from '../components/Accordion';
import LoadingSpinner from '../components/LoadingSpinner';

function InspectionDetails() {
  const { id: urlID } = useParams();
  const navigate = useNavigate();

  const [inspection, setInspection] = useState({});
  const [activeInspection, setActiveInspection] = useState(true);
  const [type, setType] = useState('');
  const [deviceList, setDeviceList] = useState({});
  const [inspectionTech, setInspectionTech] = useState({ techNotes: '' });
  const [selectedTech, setSelectedTech] = useState([]);         // Always start as an array
  const [techList, setTechList] = useState([]);
  const [logbookstatus, setLogbookStatus] = useState('');
  const [inspection_report, setInspectionReport] = useState({});
  const [activateSignature, setActivateSignature] = useState(false);
  const [panelList, setPanelList] = useState([]);
  const [ready, setReady] = useState(false);
  const [showDeviceBox, setShowDeviceBox] = useState(false);
  const [showLogBookBox, setShowLogBookBox] = useState(false);
  const [showCustomerEmailBox, setShowCustomerEmailBox] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);

  const [isModalFileGenOpen, setIsModalFileGenOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  //below for folder creation and image upload
  const [filesToUpload, setFilesToUpload] = useState([]);  // an array of files
  const [folderId, setFolderId] = useState(null);          // store created folder ID
  const [selectedFiles, setSelectedFiles] = useState([]);  // an array of files
  
  const [file, setFile] = useState(null);
  const [selectGenFile, setSelectGenFile] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchPanelList(urlID);
    fetchInspectionData(urlID);
    fetchDeviceList(urlID);
    fetchTechList(urlID);
  }, [urlID, ready]);

  // 1) Panel list
  const fetchPanelList = async (urlID) => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getPanel=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      if (response.data[0] === 'success') {
        setPanelList(response.data[1]);
      } else {
        console.error('Error getting panel list');
      }
    } catch (error) {
      console.error('Error fetching panel list:', error);
    }
  };

  // 2) Inspection Data
  const fetchInspectionData = async (urlID) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getInspectionDetails=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      if (response.data[0] === 'success') {
        const data = response.data[1];
        setInspection(data);
        setActiveInspection(true);
        setInspectionTech({ techNotes: data.tech_notes });
        setLogbookStatus(data.status);

        // If there's a customer_notification_email, prepare for signature
        if (data.customer_notification_email) {
          setActivateSignature(true);
          setInspectionReport({
            customerPrint: data.customer_print,
            customerEmail: data.customer_notification_email
          });
        }

        setType(data.type);
        // Show/hide sections
        if (data.type) {
          setShowDeviceBox(true);
          // Possibly use data[3].status checks if needed
          // setShowCustomerEmailBox(...) if logic requires
        } else {
          setShowDeviceBox(false);
          setShowCustomerEmailBox(false);
        }
      } else {
        console.error('Error getting inspection data');
      }
    } catch (error) {
      console.error('Error fetching inspection data:', error);

    } finally {
        setLoading(false);
    }
  };

  // 3) Device List
  const fetchDeviceList = async (urlID) => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getDeviceList=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      const data = response.data;
      if (data[0] === 'success') {
        setReady(data[2]);
        setDeviceList(data[1]);

        const logbook = data[4];
        // const checkListMainMissing = data[6]; // if needed

        if (ready) {
          setShowLogBookBox(true);
          if (logbook?.status) {
            setLogbookStatus(logbook.status);
            setShowCustomerEmailBox(true);
          }
        } else {
          setShowLogBookBox(false);
          setShowCustomerEmailBox(false);
        }
      } else {
        console.error('Error getting device list');
      }
    } catch (error) {
      console.error('Error fetching device list:', error);
    }
  };

  // 4) Tech List
  const fetchTechList = async (urlID) => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getAdditionalTech=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      if (response.data[0] === 'success') {
        setTechList(response.data[1]);
        
        // -- MAIN FIX: Ensure `selectedTech` is always an array --
        const possibleSelectedTech = response.data[2];
        if (Array.isArray(possibleSelectedTech)) {
          setSelectedTech(possibleSelectedTech);
        } else {
          // If it's not an array, fallback to empty array
          setSelectedTech([]);
          console.warn('selectedTech from server is not an array, defaulting to []');
        }
      } else {
        console.error('Error getting tech list');
      }
    } catch (error) {
      console.error('Error fetching tech list:', error);
    }
  };

  // Navigations
  const newInspection = (type) => {
    if (type === 'new') {
      navigate('/mdep_m_inspection/new');
    } else {
      navigate('/mdep_m_inspection/list');
    }
  };

  const signature = () => {
    navigate(`/t/tinspsignature/${urlID}`);
  };

  // Accordion toggles
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleGenUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    if(!inspection?.inspection_id){ 
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return 
    }
    const formData = new FormData();
    formData.append('status', 'submit_image');
    formData.append('inspection_id', inspection?.inspection_id);
    formData.append('file', file);
    
    try {
      const response = await axios.post(`${apiUrl}/maintenance_inspection_image_upload.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });
        setInspection(prev => ({ ...prev, image_name: response.data[0] }));
        setFile(null);
        setIsModalFileGenOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error('No file selected.', { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error('Invalid file type. Only PDF, PNG, and JPEG are allowed.', {
        position: 'top-center',
      });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error('File size exceeds 2MB.', { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };

  // Add photos
  const handleAddPhotosClick = async () => {
    if (!inspection?.job_site_address) {
      toast.error("No job site address found!", { position: 'top-center' });
      return;
    }

    try {
      // 1) Create folder
      const folderName = inspection.job_site_address;
      const response = await axios.post(`${apiUrl}/drive_folder.php`, {
        status: 'createFolder',
        inspection_id: inspection.inspection_id,
        data: { folderName }
      });
      if (response.data?.success) {
        const newFolderId = response.data.folderId;
        setFolderId(newFolderId);       // store the folder ID
        setIsModalFileGenOpen(true);    // open the modal to pick images
      } else {
        toast.error('Error creating folder: ' + response.data.error, { position: 'top-center' });
      }

    } catch (error) {
      console.error('Error creating folder', error);
      toast.error('Failed to create folder', { position: 'top-center' });
    }
  };

  // Multi-file upload
  const handleMultiFileUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!folderId) {
      toast.error("No folder ID. Please create folder first.", { position: 'top-center' });
      return;
    }
    if (!selectedFiles.length) {
      toast.error("No files selected.", { position: 'top-center' });
      return;
    }

    try {
      for (const file of selectedFiles) {
        // 1) Build a FormData for each file
        const formData = new FormData();
        formData.append('status', 'uploadFile');  // or your backend route
        formData.append('folderId', folderId);
        formData.append('file', file);

        // 2) Post to your backend
        const uploadRes = await axios.post(`${apiUrl}/drive_folder.php`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        if (!uploadRes.data?.success) {
          toast.error(`Error uploading ${file.name}: ${uploadRes.data?.error || 'Unknown error'}`, {
            position: 'top-center'
          });
        } else {
          // success => maybe you do something
        }
      }
      toast.success("All photos uploaded!", { position: 'top-center' });
      // Clear the files & close modal
      setFilesToUpload([]);
      setIsModalFileGenOpen(false);

    } catch (err) {
      console.error('Error uploading files', err);
      toast.error('Error uploading files', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };

  const handleFilesChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    // do something with selectedFiles...
    setSelectedFiles(selectedFiles);
  };


  return (
    <div className="p-5">
      <HeadingCp label={"Maintenance Inspection Details"} />
      <button
        type="button"
        onClick={handleAddPhotosClick}
        className="rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 ..."
      >
        Add Photos
      </button>

      {activeInspection && (
        <div>
          <div className="flex flex-row p-2 bg-gray-100 border-1 border-black rounded-md mb-2 p-1">
            <div className="w-full">
              <span className="font-bold text-green-700">{inspection.customer_name}</span><br/>
              <p className='text-gray-600 text-sm'>
                {inspection.job_site_address}, {inspection.job_site_address2}
              </p> 
              <span className='text-gray-600 text-sm'>
                {inspection.city} {inspection.state} {inspection.zip_code}
              </span>
              <p className="text-sm">
                {inspection.system_type} :Due {inspection.scheduled_due_date} - {inspection.schedule_date_to}
              </p>
              <p className="text-sm">
                {inspection.contact_name} : {inspection.contact_phone}
              </p>
            </div>
          </div>

          <div>
            {/* 1) All Devices */}
            <Accordion
              title="All Devices Tested and Operational (Required)"
              content={
                <DevicesTested
                  deviceList={deviceList}
                  setDeviceList={setDeviceList}
                  urlID={urlID}
                  ready={ready}
                  setReady={setReady}
                  showLogBookBox={showLogBookBox}
                  setShowLogBookBox={setShowLogBookBox}
                  setShowCustomerEmailBox={setShowCustomerEmailBox}
                />
              }
              isOpen={openIndex === 0}
              toggle={() => toggleAccordion(0)}
            />

            {/* 2) Tech Notes */}
            <Accordion
              title="Tech Notes"
              content={
                <TechNotes
                  inspectionTech={inspectionTech}
                  setInspectionTech={setInspectionTech}
                  urlID={urlID}
                />
              }
              isOpen={openIndex === 1}
              toggle={() => toggleAccordion(1)}
            />

            {/* 3) Additional Tech */}
            <Accordion
              title="Additional Tech"
              content={
                <AdditionalTech
                  selectedTech={selectedTech}   // This is now guaranteed an array
                  setSelectedTech={setSelectedTech}
                  techList={techList}
                  urlID={urlID}
                />
              }
              isOpen={openIndex === 3}
              toggle={() => toggleAccordion(3)}
            />

            {/* 4) Log Books */}
            <Accordion
              title="Log Books (Required)"
              content={
                !showLogBookBox
                  ? <span className='text-red-700'>You need to verify all devices first</span>
                  : <LogBooks
                      logbookstatus={logbookstatus}
                      setLogbookStatus={setLogbookStatus}
                      urlID={urlID}
                      setShowCustomerEmailBox={setShowCustomerEmailBox}
                    />
              }
              isOpen={openIndex === 4}
              toggle={() => toggleAccordion(4)}
            />

            {/* 5) Ticket notification */}
            <Accordion
              title="Ticket notification (Required)"
              content={
                showCustomerEmailBox && (
                  <CustomerEmail
                    inspection_report={inspection_report}
                    setInspectionReport={setInspectionReport}
                    activateSignature={activateSignature}
                    setActivateSignature={setActivateSignature}
                    urlID={urlID}
                    signature={signature}
                  />
                )
              }
              isOpen={openIndex === 5}
              toggle={() => toggleAccordion(5)}
            />
          </div>

          {/* file upload */}
          {isModalFileGenOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
              <div className="bg-white p-8 rounded-md shadow-md min-w-[500px]">
                <h2 className="text-xl font-semibold mb-4">
                  Upload Photos for {inspection?.job_site_address}
                </h2>
                <form onSubmit={handleMultiFileUpload}>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Select images (Max size 5MB each)
                    </label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"  // or "image/*" if you want all images
                      multiple   // allow multiple selection
                      onChange={handleFilesChange}
                      className="mt-2"
                    />
                  </div>
                  <div className="flex justify-end">
                   {loading ? (
                    <LoadingSpinner>Loading...</LoadingSpinner>
                    ) : (
                    <>
                      <button type="button"
                        onClick={() => {
                          setIsModalFileGenOpen(false);
                          setFilesToUpload([]);
                        }}
                        className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400">
                        Cancel
                      </button>
                      <button type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                        Upload
                      </button>       
                    </>
                   )}
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default InspectionDetails;
