import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FDNYNewInspectionModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData = {},
}) => {
  const isEdit = !!initialData.id;

  // Fields
  const [id, setId] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [jobnum, setJobnum] = useState('');
  const [tenant, setTenant] = useState('');
  const [address, setAddress] = useState('');
  const [techInspection, setTechInspection] = useState('');
  const [startTime, setStartTime] = useState('TO FOLLOW');
  const [inspector, setInspector] = useState('');
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState('');
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cot_ref, setCotRef] = useState('');

  // jobFound: null = not checked, true = found, false = not found
  const [jobFound, setJobFound] = useState(null);

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Prefill if editing
  useEffect(() => {
    if (isEdit) {
      setId(initialData.id || null);
      setFileName(initialData.file_name || null);
      setJobnum(initialData.jobnum || '');
      setTenant(initialData.tenant || '');
      setAddress(initialData.address || '');
      setTechInspection(initialData.tech_inspection || '');
      setStartTime(initialData.start_time || 'TO FOLLOW');
      setInspector(initialData.inspector || '');
      setNotes(initialData.notes || '');
      setDate(initialData.date || '');
      setCotRef(initialData.cot_ref || '');
    } else {
      setId(null);
      setFileName(null);
      setJobnum('');
      setTenant('');
      setAddress('');
      setTechInspection('');
      setStartTime('TO FOLLOW');
      setInspector('');
      setNotes('');
      setDate('');
      setCotRef('');
    }
  }, [isEdit, initialData]);

  // Fetch employees
  useEffect(() => {
    setLoading(true);
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/service_crud.php?employee_list=true`,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
          }
        );
        const employeesData = Array.isArray(response.data) ? response.data : [];
        if (employeesData.length === 0) {
          toast.error('No employees found!', { position: 'top-center' });
        }
        setEmployees(employeesData);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! You don't have access to this area.", {
            position: 'top-center',
          });
        } else {
          toast.error('Error fetching employees', { position: 'top-center' });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, [apiUrl, navigate]);

  if (!isOpen) return null;

  async function checkJobNumber(apiUrl, jobnum) {
    try {
      const response = await axios.get(`${apiUrl}/wip_crud.php?jobnum=${jobnum}`);
      if(response.data === true) {
        return true;
      } else { 
        return false;
      }
    } catch (error) {
      console.error('Error checking job number:', error);
      toast.error('Error checking job #', { position: 'top-center' });
      return false;
    }
  }
  
  const handleJobnumChange = async (e) => {
    const newValue = e.target.value;

    // 1) Check if the newValue contains ANY non-alphanumeric characters
    if (/[^a-zA-Z0-9]/.test(newValue)) {
      toast.warn('Job number can only contain letters and numbers.', { position: 'top-center' });
      // Optionally, you can remove them automatically:
      // const sanitizedValue = newValue.replace(/[^a-zA-Z0-9]/g, '');
      // setJobnum(sanitizedValue);
      // return; // if you want to stop further processing
    } else {
      // 2) If it's clean, update your state
      setJobnum(newValue);

      // 3) If too short, set jobFound to false
      if (newValue.length < 3) {
        setJobFound(false);
        return;
      }

      // 4) Otherwise, do your job lookup
      const found = await checkJobNumber(apiUrl, newValue);
      setJobFound(found);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (jobFound === false) {
      toast.error('Job number not found. Please correct before submitting.', { position: 'top-center' });
      return;
    }

    const formData = {
      data: {
        id,
        jobnum,
        tenant,
        address,
        tech_inspection: techInspection,
        start_time: startTime,
        inspector,
        notes,
        date,
        cot_ref
      },
      status: 'submit',
    };
    onSubmit(formData);
  };

  const jobNumClass = `
    border rounded w-full p-2 mt-1 text-sm
    ${jobFound === false ? 'border-red-600' : ''}
    ${jobFound === true ? 'border-green-600' : ''}
  `;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded shadow-lg w-11/12 max-w-2xl p-6 relative">
        <div className="flex justify-between items-center border-b pb-2">
          <h3 className="text-lg font-bold">
            {isEdit ? 'Edit Inspection' : 'New FDNY Inspection'}
          </h3>
          <button onClick={onClose} className="text-gray-800 hover:text-gray-800">
            &times;
          </button>
        </div>

        <form onSubmit={handleSubmit} className="mt-4 space-y-4">
          <p className="text-sm text-gray-800">* Denotes Required Field</p>

          {isEdit && (
            <>
              <input type="hidden" value={id || ''} readOnly />
              <input type="hidden" value={fileName || ''} readOnly />
            </>
          )}

          {/* Date + Job # */}
          <div className="flex space-x-4 items-center pb-2">
            <div className="flex flex-col w-1/2">
              <label className="block text-gray-800">
                Date <span className="text-red-600">*</span>
              </label>
              <input
                type="date"
                name="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
                required
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="block text-gray-800">
                Job # <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="jobnum"
                maxLength={255}
                value={jobnum}
                onChange={handleJobnumChange}
                className={jobNumClass}
                placeholder="JOB#"
              />
            </div>
          </div>

          {/* Tenant, Address */}
          <div className="flex space-x-4 items-center">
            <div className="flex flex-col w-1/4">
              <label className="block text-gray-800 mb-1">Tenant</label>
              <input
                type="text"
                name="tenant"
                maxLength={255}
                value={tenant}
                onChange={(e) => setTenant(e.target.value)}
                className="border rounded p-2 text-sm"
                placeholder="Tenant's name"
              />
            </div>

            <div className="flex flex-col w-3/4">
              <label className="block text-gray-800 mb-1">
                Project Address <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="address"
                maxLength={255}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="border rounded p-2 text-sm"
                placeholder="Job Site Address"
                required
              />
            </div>
          </div>

          {/* Time, Employee, Inspector */}
          <div className="flex space-x-4 items-center">
            <div className="flex flex-col w-1/4">
              <label className="block text-gray-800">Time</label>
              <select
                name="start_time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="border rounded p-2 mt-1 text-sm"
              >
                {isEdit && <option value={startTime}>{startTime}</option>}
                <option value="TO FOLLOW">TO FOLLOW</option>
                <option value="7:00 AM">7:00 AM</option>
                <option value="8:00 AM">8:00 AM</option>
                <option value="9:00 AM">9:00 AM</option>
                <option value="10:00 AM">10:00 AM</option>
                <option value="11:00 AM">11:00 AM</option>
                <option value="12:00 PM">12:00 PM</option>
                <option value="1:00 PM">1:00 PM</option>
                <option value="2:00 PM">2:00 PM</option>
                <option value="3:00 PM">3:00 PM</option>
                <option value="4:00 PM">4:00 PM</option>
                <option value="5:00 PM">5:00 PM</option>
                <option value="6:00 PM">6:00 PM</option>
                <option value="7:00 PM">7:00 PM</option>
                <option value="8:00 PM">8:00 PM</option>
                <option value="9:00 PM">9:00 PM</option>
                <option value="10:00 PM">10:00 PM</option>
                <option value="11:00 PM">11:00 PM</option>
              </select>
            </div>

            <div className="flex flex-col w-1/4">
              <label htmlFor="employeeSelect" className="block text-gray-800">
                Employee
              </label>
              <select
                id="employeeSelect"
                name="tech_inspection"
                value={techInspection}
                onChange={(e) => setTechInspection(e.target.value)}
                className="border rounded p-2 mt-1 text-sm"
              >
                <option value="">---</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={`${employee.first_name} ${employee.last_name}`}>
                    {employee.first_name} {employee.last_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col w-1/2">
              <label className="block">Inspector</label>
              <input
                type="text"
                name="inspector"
                value={inspector}
                onChange={(e) => setInspector(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
                placeholder="Inspector's name"
              />
            </div>

            <div className="flex flex-col w-1/4">
              <label className="block">Reference</label>
              <input
                type="text"
                name="cot_ref"
                value={cot_ref}
                onChange={(e) => setCotRef(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
                placeholder="COT REF#"
              />
            </div>
          </div>

          {/* Notes */}
          <div>
            <label className="block text-gray-800">Notes</label>
            <textarea
              rows={4}
              name="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="border rounded w-full p-2 mt-1 text-sm resize-none"
              placeholder="Any additional info..."
            />
          </div>

          {/* Buttons */}
          <div className="text-right">
            <button
              onClick={onClose}
              type="button"
              className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-4 rounded text-sm mr-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-4 rounded text-sm"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FDNYNewInspectionModal;
